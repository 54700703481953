/*
 * Modules dependencies
 */

import { AnimationController } from 'src/components/core/animations/animation-controller';
import { Button } from 'src/components/core/buttons/button';
import { CallMeModal } from 'src/components/call-me-modal';
import { FadeInUp } from 'src/components/core/animations/fade-in-up';
import { Fragment, useState } from 'react';
import { Heading } from 'src/components/core/heading';
import { Image } from 'src/components/core/image';
import { breakpoints } from '@untile/react-core/styles/breakpoints';
import { media } from '@untile/react-core/styles/media';
import { transparentize } from 'src/styles/utils/colors';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useTranslation } from 'next-i18next';
import phoneIcon from 'src/assets/svgs/phone.svg';
import styled from 'styled-components';
import supportIcon from 'src/assets/svgs/support.svg';

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-column-gap: var(--space-xs);
  grid-template-columns: repeat(2, 1fr);
`;

/**
 * `Card` styled component.
 */

const Card = styled.div`
  --card-support-background-color: var(--color-cyan100);

  background-color: var(--card-support-background-color);
  border-radius: var(--border-radius-sm);
  height: 100%;

  &[data-theme-dark='true'] {
    --card-support-background-color: ${transparentize('cyan800', 0.16)};
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled(FadeInUp)`
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  position: relative;

  ${media.min.md`
    min-height: 280px;
  `}

  ${media.min.xl`
    min-height: 306px;
  `}
`;

/**
 * Export `CardSupport` component.
 */

export function CardSupport({ isDark }: { isDark?: boolean }) {
  const { t } = useTranslation('common');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useBreakpoint(0, 'md');
  const Component = isMobile ? Fragment : Grid;

  return (
    <AnimationController>
      <Component>
        <FadeInUp verticalOffset={isMobile ? 8 : 40}>
          <Card data-theme-dark={isDark}>
            <div style={{ padding: 'var(--space-ms) var(--space-sm) var(--space-sm)' }}>
              <Heading
                asDescription={'p'}
                asTitle={'h2'}
                description={t('common:cards.support.description')}
                descriptionSize={16}
                descriptionTheme={isDark ? 'slate50' : 'slate900'}
                icon={supportIcon}
                label={t('common:cards.support.title')}
                shouldAnimate={false}
              />

              <Button
                aria-label={t('common:actions.callMe')}
                colorTheme={isDark ? 'outlineDark' : 'outline'}
                icon={phoneIcon}
                onClick={() => setIsModalOpen(true)}
                reverse
                size={'medium'}
                style={{ marginTop: 'var(--space-xs)' }}
              >
                {t('common:actions.callMe')}
              </Button>

              <CallMeModal isOpen={isModalOpen} onRequesClose={() => setIsModalOpen(false)} />
            </div>
          </Card>
        </FadeInUp>

        {!isMobile && (
          <ImageWrapper>
            <Image
              alt={t('common:cards.support.image.alt')}
              fill
              sizes={`(max-width: ${breakpoints.xxl}px) 50vw, 50vw`}
              src={t('common:cards.support.image.src')}
              style={{ objectFit: 'cover' }}
            />
          </ImageWrapper>
        )}
      </Component>
    </AnimationController>
  );
}
