/**
 * Module dependencies.
 */

import { CardSupport } from 'src/components/core/cards/card-support';
import { GetStaticProps, NextPage } from 'next';
import { Grid, SubGrid } from 'src/components/core/layout/grid-content';
import { Heading } from 'src/components/core/heading';
import { Hero } from 'src/components/sections/hero';
import { SEO } from 'src/components/core/seo';
import { SSGProps } from 'src/types/app';
import { Section } from 'src/components/core/layout/section';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useTranslation } from 'next-i18next';
import { withSettings } from 'src/hocs/with-settings';
import styled from 'styled-components';

/**
 * `StyledSection` styled component.
 */

const StyledSection = styled(Section)`
  --section-padding-top: 88px;
`;

/**
 * `Page404` page.
 */

const Page404: NextPage = () => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint(0, 'lg');

  return (
    <>
      <SEO title={t('error:title')} />

      <Hero>
        <Heading
          description={t('error:hero.description')}
          descriptionSize={18}
          label={t('error:hero.label')}
          labelSize={14}
          lead={t('error:hero.lead')}
          staggerMobile
          title={t('error:hero.title')}
          titleSize={36}
          titleTheme={'slate50'}
        />
      </Hero>

      <StyledSection style={{ marginBottom: isMobile ? '204px' : '120px' }}>
        <Grid>
          <SubGrid>
            <CardSupport isDark />
          </SubGrid>
        </Grid>
      </StyledSection>
    </>
  );
};

/**
 * Export `getStaticProps`.
 */

export const getStaticProps: GetStaticProps<SSGProps> = withSettings<any>(async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale as string, ['common', 'error'])),
      hideFooter: true,
      hideNavbarBackground: true
    },
    revalidate: 60
  };
});

/**
 * Export `Page404` page.
 */

export default Page404;
